<script>
/* eslint-disable */
import appConfig from '@/app.config';

/**
 * Lock screen page -component
 */
export default {
  page: {
    title: 'Lockscreen',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link class="text-dark" to="/">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-soft bg-primary">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">Lock screen</h5>
                      <p>Enter your password to unlock the screen!</p>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img
                      alt
                      class="img-fluid"
                      src="@/assets/images/profile-img.png"
                    />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <a href="/">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          alt
                          class="rounded-circle"
                          height="34"
                          src="@/assets/images/logo.svg"
                        />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="p-2">
                  <form class="form-horizontal">
                    <div class="user-thumb text-center mb-4">
                      <img
                        alt="thumbnail"
                        class="rounded-circle img-thumbnail avatar-md"
                        src="@/assets/images/users/avatar-1.jpg"
                      />
                      <h5 class="font-size-15 mt-3">Maria Laird</h5>
                    </div>

                    <div class="mb-3">
                      <label for="userpassword">Password</label>
                      <input
                        id="userpassword"
                        class="form-control"
                        placeholder="Enter password"
                        type="password"
                      />
                    </div>

                    <div class="row mb-0">
                      <div class="col-12 text-end">
                        <button class="btn btn-primary w-md" type="submit">
                          Unlock
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center">
              <p>
                Not you ? return
                <router-link
                  class="fw-medium text-primary"
                  to="/auth/login-1"
                >Sign In
                </router-link
                >
              </p>
              <p>
                © {{ new Date().getFullYear() }} Skote. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Themesbrand
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
